.App {
  text-align: center;
}

.body {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  padding: 7rem 15%;
}

.App-link {
  color: #61dafb;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
}

.sub-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: black;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15%;
}

.profile-img {
  width: 600px;
  padding: 0 16px;
}

.profile-text {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

.profile-subtext {
  font-weight: 200;
}

.profile-name {
  font-size: 30px;
}

.work-title { 
  font-family: 'Montserrat', sans-serif;
}

.articles {
  padding-bottom: 15%;
}