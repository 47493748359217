ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    padding: 0 15%;
}

li {
    float: left;
}

li a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

.name {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.pages {
    display: flex;
    align-items: center;
}