.article-photo { 
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    object-fit: cover;
}

.article { 
    display: flex;
    flex-direction: column;
}

.article-row { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 5% 0;
}

.article-title { 
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
}